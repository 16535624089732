import { FormattedHTMLMessage, FormattedMessage, injectIntl } from "gatsby-plugin-intl";
import React from "react";
import {
  logoLilacosta,
  logoMKCs,
  logoMKEn,
  logoMPOCs,
  logoNGEuCs,
  logoNGEuDe,
  logoNGEuEn,
  logoNPO,
  logoPoolmatix
} from "../../components/Images";
import Row from "../../components/Row";
import styled from "../../styling/styled";
import { theme } from "../../styling/theme";

const ColNews = styled.div`
  max-width: calc(100% / 3);
  flex-basis: calc(100% / 3);
  border-bottom: 1px solid #d7d7d7;
  ${[theme.media.maxM]} {
    max-width: 50%;
    flex-basis: 50%;
  }
  ${[theme.media.maxS]} {
    max-width: 100%;
    flex-basis: 100%;
  }
  &:nth-last-of-type(1) {
    border: none;
  }
  ${[theme.media.s]} {
    &:nth-last-of-type(-n+2) {
      border: none;
    }
  }
  ${[theme.media.m]} {
    &:nth-last-of-type(-n+3) {
      border: none;
    }
  }
`;

const Post = styled.div`
  max-width: 255px;
  padding-top: 52px;
  padding-bottom: 52px;
  ${[theme.media.maxM]} {
    max-width: 100%;
    padding-top: 30px;
    padding-bottom: 10px;
  }
`;

const PostHeading = styled.h3`
  margin-bottom: 0;
  word-break: break-word;
  overflow-wrap: break-word;
`;

const PostContent = styled.div`
  p {
    margin-bottom: 0;
    hyphens: auto;
  }

  a {
    color: #000952;
    word-break: break-all;
  }
`;

// Add this constant at the top of the file, after imports
const ARTICLES_PER_PAGE = 6;

class Articles extends React.Component {
  state = {
    visibleArticles: ARTICLES_PER_PAGE
  };

  getLogoNGEuSource = () => {
    const { intl } = this.props;

    switch (intl.locale) {
      case "cs":
        return logoNGEuCs;
      case "en":
        return logoNGEuEn;
      case "de":
        return logoNGEuDe;
      default:
        return logoNGEuEn;
    }
  };

  getLogoMKSource = () => {
    const { intl } = this.props;

    switch (intl.locale) {
      case "cs":
        return logoMKCs;
      case "en":
        return logoMKEn;
      default:
        return logoMKEn;
    }
  };

  getTotalArticles = () => {
    const { intl } = this.props;
    let count = 0;
    
    // Check for existence of translation keys until we find one that doesn't exist
    while (intl.messages[`partials.news.articles.${count}.title`]) {
      count++;
    }
    
    return count;
  };

  handleLoadMore = () => {
    this.setState(prevState => ({
      visibleArticles: prevState.visibleArticles + ARTICLES_PER_PAGE
    }));
  };

  renderLogos = (index) => {
    switch (index) {
      case 18:
      case 14:
        return (
          <>
            <img src={logoNPO} alt="" />
            <img src={logoMPOCs} alt="" />
            <img src={this.getLogoNGEuSource()} alt="" />
          </>
        );
      case 15:
        return (
          <>
            <img src={logoNPO} alt="" />
            <img src={this.getLogoMKSource()} alt="" />
            <img src={this.getLogoNGEuSource()} alt="" />
          </>
        );
      case 17:
        return <img src={logoPoolmatix} alt="" />;
      case 16:
        return <img src={logoLilacosta} alt="" />;
      default:
        return null;
    }
  };

  render() {
    
    const totalArticles = this.getTotalArticles();
    const articles = Array.from({ length: totalArticles }, (_, i) => (totalArticles - 1 - i));
    const { visibleArticles } = this.state;

    return (
      <>
        <Row spacing={15}>
          {articles.slice(0, visibleArticles).map(index => (
            <ColNews key={index}>
              <Post>
                <PostHeading>
                  <FormattedMessage id={`partials.news.articles.${index}.title`} />
                </PostHeading>
                <p>
                  <FormattedHTMLMessage id={`partials.news.articles.${index}.date`} />
                </p>
                <PostContent>
                  <FormattedHTMLMessage id={`partials.news.articles.${index}.text`} />
                  {this.renderLogos(index)}
                </PostContent>
              </Post>
            </ColNews>
          ))}
        </Row>
        {visibleArticles < articles.length && (
          <div style={{ textAlign: 'center', marginTop: '30px' }}>
            <button 
              onClick={this.handleLoadMore}
              style={{
                padding: '10px 20px',
                backgroundColor: '#000952',
                color: 'white',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer'
              }}
            >
              <FormattedMessage id="partials.news.loadMore" defaultMessage="Načíst další" />
            </button>
          </div>
        )}
      </>
    );
  }
}

export default injectIntl(Articles);
